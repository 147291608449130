import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, message} from 'antd';
import './Press.scss';
import {Cell, Grid, Row} from '../Grid';
import UserContext from '../../context/UserContext';
import LiveFeed from './LiveFeed';
import InteractionSection from './InteractionSection';
import Zoom from './Zoom';
import {
	approveAndAskQuestionApi, approveQuestionList,
	getAllEventsData,
	getAllQuestionsDataApi,
	getInteractionApi,
	submitQuestionsApi
} from './api/press-api';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CountdownTimer from './CountdownTimer';
import playIcon from '../../../../public/images/play-icon.svg'
import { MemoizedExternalVideo as ExternalVideo } from '@sailgp/sailgp-shared-components';

export default function PressConference(props) {
	const contentfulData = props && props.data;
	const [question, setQuestion] = useState('');
	const [eventData, setEventData] = useState();
	const [selectedOption, setSelectedOption] = useState('');
	const [approvedQuestionId, setApprovedQuestionId] = useState('');
	const [zoomMeetingId, setZoomMeetingId] = useState();
	const [meetingNumber, setMeetingNumber] = useState();
	const [password, setPassword] = useState(null);
	const [mediaUrl, setMediaUrl] = useState();
	const [photoUrl, setPhotoUrl] = useState();
	const [id, setId] = useState();
	const [open, setOpen] = useState(false);
	const [askedQuestionId, setAskedQuestionId] = useState('');
	const [allQuestionList, setAllQuestionList] = useState([]);
	const [isSubmitQuestion, setIsSubmitQuestion] = useState(false);
	const [askedQuestionData, setAskedQuestionData] = useState([]);
	const [journalistAskedQuestions, setJournalistAskedQuestions] = useState([]);
	const [approvedQuestions, setApprovedQuestions] = useState([])
	const [raceReplay, setRaceReply] = useState('')
	const [messageApi, contextHolder] = message.useMessage();
	const {user,logOut} = useContext(UserContext)
	const [permanentRole, setPermanentRole] = useState('')
	const [isContentfulPC, setIsContentfulPC] = useState(false)
	const [isPCCountdownEnded, setIsPCCountdownEnded] = useState(false)
	const [isBeforePressConf, setIsBeforePressConf] = useState(false)
	const [isDuringPressConf, setIsDuringPressConf] = useState(false)
	const [isEndOfPressConf, setIsEndOfPressConf] = useState(false)
	const [form] = Form.useForm();
	const {isSm, isMd, isLg, isXL} = useWindowDimensions()

	const getAllEventsDataFromApi = async () => {
		const selectedEventData = await getAllEventsData(permanentRole, contentfulData.pressConferenceId);
		if (selectedEventData) {
			if( selectedEventData !== undefined &&  selectedEventData.hasOwnProperty("status") && selectedEventData.status == "logout"){
				logOut()
			}
			await setEventData(selectedEventData);
			if (selectedEventData.zoomMeetingId) {
				setZoomMeetingId(selectedEventData.zoomMeetingId);
				setMeetingNumber(Number(selectedEventData.zoomMeetingId.split(' ').join('')))
				setPassword(selectedEventData.zoomPassword);
			}
			if (selectedEventData.mediaUrl) {
				setMediaUrl(selectedEventData.mediaUrl);
			}
			if (selectedEventData.backgroundImageUrl) {
				setPhotoUrl(selectedEventData.backgroundImageUrl)
			}
			setId(selectedEventData._id);
		}
	}
	const getAllQuestionsData = async (conferenceId, userRole) => {
		const allQuestion = await getAllQuestionsDataApi(conferenceId, userRole);
		if(allQuestion !== undefined && allQuestion["status"] !== undefined && allQuestion.status == "logout"){
			logOut()
		}
		setAllQuestionList(allQuestion)
	}

	const getInteractionData = async () => {
		const interactionApiRes = await getInteractionApi(id, permanentRole);
		if (interactionApiRes && interactionApiRes.length > 0) {
			setAskedQuestionData(interactionApiRes);
			const filteredApiRes = interactionApiRes.filter(question => question.status === '2')
			setJournalistAskedQuestions(filteredApiRes)
		}
	}

	useEffect(() => {
		if(contentfulData.pressConferenceId) getAllEventsDataFromApi();
		else {
			setIsContentfulPC(true)
			setEventData({
				eventTitle: contentfulData.eventTitle,
				eventDescription: contentfulData.eventDescription,
				eventDate: contentfulData.eventDate,
				eventEndDate: contentfulData.eventEndTime,
				questionsBeforePC: 'No'
			})
			setPhotoUrl(contentfulData.eventImage.file.url)
		}
	}, []);

	useEffect(() => {
		if (id) {
			getAllQuestionsData(id, permanentRole);
			// Debounce function to fetch live feed data and keep updated
			const delayDebounceFn = setInterval(() => {
				getAllEventsDataFromApi();
				getAllQuestionsData(id, permanentRole);
			}, 5000);
			return () => clearInterval(delayDebounceFn);
		}
	}, [id, askedQuestionId]);

	useEffect(() => {
		document.body.style.overflow = 'auto';
	}, []);

	useEffect(() => {
		const role = sessionStorage.getItem('userRole')|| user.profileInfo;
		setPermanentRole(role)
	}, [])

	const handleChangeQuestion = event => {
		const value = event.target.value;
		setQuestion(value);
	};
	const handleChange = value => {
		setSelectedOption(value);
	};

	const onSubmit = async () => {
		const payLoadBody = {
			event: id,
			question: question,
			participant: selectedOption || 'Panel',
			status: '0'
		};
		if (Object.keys(payLoadBody).length > 0) {
			const submitRes = await submitQuestionsApi(payLoadBody);
			if (submitRes.status === 200) {
				setQuestion('');
				messageApi.open({type: 'success', content: contentfulData.createQuestionSuccess});
				form.resetFields();
				setIsSubmitQuestion(true)
			} else {
				messageApi.open({type: 'error', content: contentfulData.createQuestionError});
			}
			setIsSubmitQuestion(false)
		}
	};

	const approveQuestion = async (id) => {
		const payLoadBody = {
			status: '1',
			questionfeedback: 'approved'
		};

		if (Object.keys(payLoadBody).length > 0) {
			const approveRes = await approveAndAskQuestionApi(id, payLoadBody);
			if (approveRes.status === 200) {
				setApprovedQuestionId(id);
				messageApi.open({type: 'success', content: contentfulData.approveQuestionSuccess});
			} else {
				messageApi.open({type: 'error', content: contentfulData.approveQuestionError});
			}
		}
	};

	const askQuestion = async (id) => {
		const payLoadBody = {
			status: '2',
			questionfeedback: 'pending'
		};
		if (Object.keys(payLoadBody).length > 0) {
			const askQuestionRes = await approveAndAskQuestionApi(id, payLoadBody);
			if (askQuestionRes.status === 200) {
				setAskedQuestionId(id);
				messageApi.open({type: 'success', content: contentfulData.askQuestionSuccess});
			} else {
				messageApi.open({type: 'error', content: contentfulData.askQuestionError});
			}
		}
	};

	const getApprovedQuestions = async () => {
		const approveQuestionListRes = await approveQuestionList(id);
		const approvedValueToSet = approveQuestionListRes ? approveQuestionListRes : []
		setApprovedQuestions(approvedValueToSet);
	}

	useEffect(() => {
		if (eventData && eventData.eventDate) {
			const now = new Date().getTime();
			setIsBeforePressConf(now <= new Date(eventData.eventDate).getTime());
			setIsDuringPressConf(
				now >= new Date(eventData.eventDate).getTime() && now <= new Date(eventData.eventEndDate).getTime()
			);
			setIsEndOfPressConf(now >= new Date(eventData.eventEndDate).getTime());
		}
	}, [isPCCountdownEnded, eventData]);

	useEffect(() => {
		if (!eventData || !eventData.eventEndDate) return;

		const eventEndTime = new Date(eventData.eventEndDate).getTime();

		const interval = setInterval(() => {
			const now = new Date().getTime();
			if (now >= eventEndTime) {
				setIsEndOfPressConf(true);
				clearInterval(interval);
			}
		}, 1000);

		return () => clearInterval(interval);

	}, [eventData]);


	const hasEventData = eventData && eventData.eventDate;
	const isBeforeRace1 = hasEventData && new Date().getTime() >=  new Date(eventData.eventEndDate).getTime() && new Date().getTime() <= new Date(eventData.liveRace1StartDate).getTime()
	const isDuringRace1 = hasEventData && new Date().getTime() >= new Date(eventData.liveRace1StartDate).getTime() && new Date().getTime() <= new Date(eventData.liveRace1EndDate).getTime()
	const isBeforeRace2 = hasEventData && new Date().getTime() >=  new Date(eventData.liveRace1EndDate).getTime() && new Date().getTime() <= new Date(eventData.liveRace2StartDate).getTime()
	const isDuringRace2 = hasEventData && new Date().getTime() >= new Date(eventData.liveRace2StartDate).getTime() && new Date().getTime() <= new Date(eventData.liveRace2EndDate).getTime()
	let isEndOfEvent = false

	if(hasEventData && isEndOfPressConf && (!eventData.liveRace1StartDate && !eventData.liveRace2StartDate)){
		isEndOfEvent = true
	} else if(hasEventData && isEndOfPressConf && (eventData.liveRace1StartDate && !eventData.liveRace2StartDate) ) {
		isEndOfEvent = new Date().getTime() >=  new Date(eventData.liveRace1EndDate).getTime()
	} else if(hasEventData && isEndOfPressConf && eventData.liveRace2StartDate ) {
		isEndOfEvent = new Date().getTime() >=  new Date(eventData.liveRace2EndDate).getTime()
	}

	const hasRaceReplays = eventData && (!!eventData.raceReplay1Url || !!eventData.raceReplay2Url)
	const countdownTitle =  hasEventData && (isBeforePressConf ? eventData.eventTitle : eventData.liveRaceTitle);
	const countdownDescription = hasEventData && (isBeforePressConf ? eventData.eventDescription : eventData.liveRaceDescription)
	const countdownValue = hasEventData && (isBeforePressConf ? eventData.eventDate : isBeforeRace1 ? eventData.liveRace1StartDate : eventData.liveRace2StartDate);
	const streamingUrl = hasEventData && (isDuringPressConf ? mediaUrl : isDuringRace1 ? eventData.liveRace1Url : eventData.liveRace2Url);

	const getActionBanner = () => (
		<div className="banner-container">
			<div className="media-container">
				<div className="desktop">
					<img
						src={photoUrl}
						className="typeActionBanner"
						alt={countdownTitle || 'Hero image'}
					/>
				</div>
				<div className="mobile">
					<img
						src={photoUrl}
						className="typeActionBanner"
						alt={countdownTitle || 'Hero image'}
					/>
				</div>
			</div>
			<div className="content-parent">
				<div className="content-container">
					<h2 className="c-team-listing__heading">{countdownTitle}</h2>
					<p className="content-container__description">{countdownDescription}</p>
					<h4 className="c-team-listing__conference">
						{isBeforePressConf
							? 'PRESS CONFERENCE'
							: isEndOfEvent
								? 'EVENT FINISHED'
								: 'RACE'}
						<br />
						{!isEndOfEvent ? 'LIVE IN:' : ''}
					</h4>
					{!isEndOfEvent ? (
						<CountdownTimer
							dateFrom={new Date(countdownValue)}
							setIsCountdownEnded={setIsPCCountdownEnded}
						/>
					) : null}
				</div>
			</div>
		</div>
	);


	return (
		<section className={`press-livefeed-container ${(isBeforePressConf && eventData.questionsBeforePC === 'Yes') ? 'press-livefeed-container__questionsBeforePC ' :''} ${raceReplay && isEndOfEvent ? 'press-livefeed-container__race-replay' : ''}`}>
			{eventData && eventData.eventDate && photoUrl && ((isBeforePressConf && eventData.questionsBeforePC === 'No') || isBeforeRace1 || isBeforeRace2 || (isEndOfEvent && !hasRaceReplays)) ?
				getActionBanner()
				: null }
			{isEndOfEvent && !isBeforePressConf && !isDuringPressConf && !isBeforeRace1 && !isDuringRace1 && !isBeforeRace2 && !isDuringRace2 && hasRaceReplays && !raceReplay ?
				<div className="banner-container">
					<div className="media-container">
						<div className="desktop">
							<img
								src={photoUrl}
								className='typeActionBanner'
								alt={countdownTitle || 'Hero image'}/>
						</div>
						<div className="mobile">
							<img
								src={photoUrl}
								className='typeActionBanner'
								alt={countdownTitle || 'Hero image'}/>
						</div>
					</div>
					<div>
						<div className='content-container'>
							<h2
								className='c-team-listing__heading'>
								{eventData.eventTitle}
							</h2>
							<p
								className='content-container__description'>
								{eventData.eventDescription}
							</p>
							<div className='button-container'>
								{eventData.raceReplay1Url && eventData.raceReplay1Url !== 'undefined' && <button className='button-container__button' onClick={() => setRaceReply(eventData.raceReplay1Url)}><span><img src={playIcon} alt={'play icon'}/></span>WATCH RACE DAY 1</button>}
								{eventData.raceReplay2Url && eventData.raceReplay2Url !== 'undefined' && <button className='button-container__button' onClick={() => setRaceReply(eventData.raceReplay2Url)}><span><img src={playIcon} alt={'play icon'}/></span>WATCH RACE DAY 2</button>}
							</div>
						</div>

					</div>

				</div>
				: null }
			{raceReplay ?
				<div className="video-responsive-race-replay">
					<iframe
						src={raceReplay}
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						title="Embedded youtube"
						width='100%'
						height='100%'
					/>
				</div>
				: null}
			{eventData && isContentfulPC && isDuringPressConf && contentfulData.pressConferenceVideo ? <div>
				<ExternalVideo data={contentfulData.pressConferenceVideo} disableConstraint/>

			</div> : null}
			{eventData && !isContentfulPC && eventData.eventDate && photoUrl && ( (isBeforePressConf && eventData.questionsBeforePC === 'Yes') || isDuringPressConf || isDuringRace1 || isDuringRace2 ) ?
				<Grid noHorizontalPadding noVerticalPadding className='press-livefeed-container__grid'>
					{isLg || isXL ?
						<Row>
							<Cell lgCols={9} xlCols={9} mdCols={12} smCols={12}>
								<Row className="press-live-feed">
									{contextHolder}
									<Cell lgCols={12} xlCols={12} mdCols={6} smCols={2}>
										{isBeforePressConf ?
											getActionBanner() :
											<div className="live-streaming video-responsive">
												{zoomMeetingId ? (
													<Zoom
														zoomMeetingNumber={meetingNumber}
														password={password}
														contentfulData={contentfulData}
													/>
												) : (
													<iframe
														src={streamingUrl}
														frameBorder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
														allowFullScreen
														title="Embedded youtube"
													/>
												)}
											</div> }
									</Cell>
								</Row>
								<Row>
									<InteractionSection
										id={id}
										approvedQuestionId={approvedQuestionId}
										askedQuestionId={askedQuestionId}
										askQuestion={askQuestion}
										contentfulData={contentfulData}
										question={question}
										permanentRole={permanentRole}
										isSubmitQuestion={isSubmitQuestion}
										getInteractionData={getInteractionData}
										askedQuestionData={askedQuestionData}
										journalistAskedQuestions={journalistAskedQuestions}
										getApprovedQuestions={getApprovedQuestions}
										approvedQuestions={approvedQuestions}
									/>
								</Row>
							</Cell>
							<Cell lgCols={3} xlCols={3} mdCols={3} smCols={2}>
								{eventData != undefined && (
									<LiveFeed
										id={id}
										contentfulData={contentfulData}
										question={question}
										handleChangeQuestion={handleChangeQuestion}
										handleChange={handleChange}
										onSubmit={onSubmit}
										approveQuestion={approveQuestion}
										allQuestionList={allQuestionList}
										approvedQuestionId={approvedQuestionId}
										validation={contentfulData.questionValidation}
										form={form}
										eventData={eventData}
										open={open}
										permanentRole={permanentRole}
										askedQuestionData={askedQuestionData}
										getApprovedQuestions={getApprovedQuestions}
										approvedQuestions={approvedQuestions}
									/>
								)}
							</Cell>
						</Row> : null}
					{isMd || isSm ?
						<Row>
							<Cell lgCols={9} xlCols={9} mdCols={12} smCols={12}>
								<Row className="press-live-feed">
									{contextHolder}
									<Cell lgCols={12} xlCols={12} mdCols={6} smCols={2}>
										{isBeforePressConf ?
											getActionBanner() :
											<div className="live-streaming video-responsive">
												{zoomMeetingId ? (
													<Zoom
														zoomMeetingNumber={meetingNumber}
														password={password}
														contentfulData={contentfulData}
													/>
												) : (
													<iframe
														src={streamingUrl}
														frameBorder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
														allowFullScreen
														title="Embedded youtube"
													/>
												)}
											</div>
										}
									</Cell>
								</Row>
							</Cell>
							<Cell lgCols={9} xlCols={9} mdCols={12} smCols={2}>
								<Row className='press-live-feed__interactions-row'>
									<Cell mdCols={3} smCols={2}>
										<InteractionSection
											id={id}
											approvedQuestionId={approvedQuestionId}
											askedQuestionId={askedQuestionId}
											askQuestion={askQuestion}
											contentfulData={contentfulData}
											question={question}
											permanentRole={permanentRole}
											isSubmitQuestion={isSubmitQuestion}
											getInteractionData={getInteractionData}
											askedQuestionData={askedQuestionData}
											journalistAskedQuestions={journalistAskedQuestions}
											getApprovedQuestions={getApprovedQuestions}
											approvedQuestions={approvedQuestions}
										/>
									</Cell>
									<Cell mdCols={3} smCols={2}>
										{eventData != undefined && (
											<LiveFeed
												id={id}
												contentfulData={contentfulData}
												question={question}
												handleChangeQuestion={handleChangeQuestion}
												handleChange={handleChange}
												onSubmit={onSubmit}
												approveQuestion={approveQuestion}
												allQuestionList={allQuestionList}
												approvedQuestionId={approvedQuestionId}
												validation={contentfulData.questionValidation}
												form={form}
												eventData={eventData}
												open={open}
												permanentRole={permanentRole}
												getApprovedQuestions={getApprovedQuestions}
												approvedQuestions={approvedQuestions}
											/>
										)}
									</Cell>
								</Row>
							</Cell>
						</Row> : null}
				</Grid>
				:
				null
			}
		</section>
	);
}

PressConference.propTypes = {
	contentfulData: PropTypes.object,
	permanentRole: PropTypes.object,
};
