import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import UserContext from '../../context/UserContext';
import { getSsoAPIURL } from '../../lib/content-api-utils';
import {fetchPostData, fetchPostUploadImage, userLogout} from '../../utils/fetchApi';
import {fetchPageData} from '../../utils/fetchApiData';
import {
	fetchUserDetailsAPI,
	resetPasswordApiCall,
	userLoggedInApi,
	uploadUserProfilePicture
} from './api/users-provider-api';

const UserProvider = ({children}) => {

	const mockUser = {
		userId: '',
		userPermissionLevel: '',
		avatar: '',
		fullName: '',
		profileInfo: '',
		jobTitle: '',
		firstName: '',
		lastName: '',
		company: '',
		email: '',
		phoneNumber: '',
		gender: ''
	}
	let userData;

	if (typeof window !== 'undefined' && window.sessionStorage && sessionStorage.getItem('userDetails')) {
		userData = JSON.parse(sessionStorage.getItem('userDetails'))
	} else {
		userData = mockUser
	}

	const [user, setUser] = useState(userData)
	const tokenValue = typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem('token') ? true : false;

	const [isLoggedIn, setIsLoggedIn] = useState(tokenValue);

	useEffect(() => {
		sessionStorage.setItem('userDetails', JSON.stringify(user));
	}, [user])

	const resetPassword = async (emailId, reset, url) => {
		await resetPasswordApiCall(emailId, reset, url);
		toast.dismiss()
		location.assign('/user/password-reset-confirm/')
	}

	const ssoLogin= async (userDataSSO) => {
		const { userDetails } = userDataSSO
		const profileImageInfo = await uploadUserProfilePicture();
		let imgURL = '';
		if (profileImageInfo.status === 1) {
			if (profileImageInfo.data.profileimage && !profileImageInfo.data.profileimage.includes('undefined')) {
				imgURL = profileImageInfo.data.profileimage
			}
		}
		sessionStorage.removeItem('userRole')
		sessionStorage.setItem('token', 'true');

		let aLevel = null;
		if (userDetails.User_Role === 'Admin') {
			aLevel = 1;
		} else if (userDetails.User_Role === 'PressOffice') {
			aLevel = 2;
		} else if (userDetails.User_Role === 'Journalist') {
			aLevel = 3;
		}

		const userObj = {
			userId: userDetails.id,
			avatar: imgURL !== '' && imgURL,
			fullName: userDetails.firstName + ' ' + userDetails.lastName,
			profileInfo: userDetails.User_Role,
			jobTitle: userDetails.title,
			firstName: userDetails.firstName,
			lastName: userDetails.lastName,
			country: userDetails.country,
			company: userDetails.organization,
			email: userDetails.emailAddress,
			dateOfBirth: new Date(userDetails.dateOfBirth),
			phoneNumber: userDetails.mobile ? userDetails.mobile : null,
			userPermissionLevel: aLevel,
			gender: userDetails.gender
		}

		setUser(userObj);
		setIsLoggedIn(true);
		sessionStorage.setItem('userDetails', JSON.stringify(userObj));
	}

	const logIn = async (obj, reset) => {
		const loginUrl = await getSsoAPIURL('user/login');
		const res = await fetchPostData(
			loginUrl,
			'POST',
			{ ...obj, successUrl: '/', successUrl2: '/', applicationName: process.env.APP_NAME_MEDIAHUB},
			true
		);

		const loginResponseData = await res.json()

		if (loginResponseData.status === 'success') {
			const { userDetails } = loginResponseData
			const profileImageInfo = await uploadUserProfilePicture();
			let imgURL = '';
			if (profileImageInfo.status === 1) {
				if (profileImageInfo.data.profileimage && !profileImageInfo.data.profileimage.includes('undefined')) {
					imgURL = profileImageInfo.data.profileimage
				}
			}
			sessionStorage.removeItem('userRole')
			sessionStorage.setItem('token', 'true');

			let aLevel = null;
			if (userDetails.User_Role === 'Admin') {
				aLevel = 1;
			} else if (userDetails.User_Role === 'PressOffice') {
				aLevel = 2;
			} else if (userDetails.User_Role === 'Journalist') {
				aLevel = 3;
			}

			setUser({
				userId: userDetails.id,
				avatar: imgURL !== '' && imgURL,
				fullName: userDetails.firstName + ' ' + userDetails.lastName,
				profileInfo: userDetails.User_Role,
				jobTitle: userDetails.title,
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
				country: userDetails.country,
				company: userDetails.organization,
				email: userDetails.emailAddress,
				dateOfBirth: new Date(userDetails.dateOfBirth),
				phoneNumber: userDetails.mobile ? userDetails.mobile : null,
				userPermissionLevel: aLevel,
				gender: userDetails.gender
			});
			setIsLoggedIn(true);
			return loginResponseData
		} else {
			setIsLoggedIn(false);
			reset();
			return loginResponseData;
		}
	}

	const logOut = async () => {
		await setIsLoggedIn(false);
		await sessionStorage.clear()
		await userLogout()
		window.location.href = '/user/login'
	}

	const uploadAvatar = async (e) => {
		const formData = new FormData();
		formData.append('profileImage', e.target.files[0]);
		const postProfileImageUrl = await fetchPageData(`user/uploadImage`);
		const profileImage = await fetchPostUploadImage(
			postProfileImageUrl,
			'POST',
			formData,
			true
		);
		if (profileImage.status === 200) {
			const profileStatus = await uploadUserProfilePicture();
			if (profileStatus.status === 1) {
				if (profileStatus.data.profileimage.includes('undefined')) {
					toast.error('Unable to Upload Image', {position: toast.POSITION.TOP_CENTER});
				} else {
					setUser(prev =>
						({
							...prev,
							avatar: profileStatus.data.profileimage
						}))
					toast.success('Image Uploaded Successfully', {position: toast.POSITION.TOP_CENTER});
				}
			} else {
				toast.error('Unable to Upload Image', {position: toast.POSITION.TOP_CENTER});
			}
		} else {
			toast.error('Unable to Upload Image', {position: toast.POSITION.TOP_CENTER});
		}
	}


	const registerUser = (formData) => {
		const newUser = {
			userId: 'abc123',
			userPermissionLevel: 0,
			avatar: 'http://images.ctfassets.net/2lppn7hwgzta/5TCLjBEZti4u0wS5I81MKo/9aab0bb919ed935cd6b2545d40b3b6df/SYD_subnav.webp?fm=webp&w=1920',
			fullName: `${formData.firstName} ${formData.lastName}`,
			profileInfo: formData.jobTitle,
			jobTitle: formData.jobTitle,
			firstName: formData.firstName,
			lastName: formData.lastName,
			company: formData.company,
			email: formData.email,
			phoneNumber: formData.phoneNumber,
			dateOfBirth: formData.dateOfBirth,
			applicationName: process.env.APP_NAME_MEDIAHUB
		}

		setUser(newUser)
		setIsLoggedIn(true)

	}

	const updateProfile = async (data) => {

		const userData = {
			firstName: data.firstName,
			lastName: data.lastName,
			gender: data.gender,
			emailAddress: user.email,
			dateOfBirth: data.dateOfBirth,
			country: data.country,
			mobile: data.phoneNumber,
			organization: data.company,
			title: data.jobTitle,
			applicationName: process.env.APP_NAME_MEDIAHUB
		  };

		const postUrl = await getSsoAPIURL('user/update-preferences');

		const settingResponseDataJson = await fetchPostData(
			postUrl,
			'POST',
			userData,
			true
		);
		const settingResponseData = await settingResponseDataJson.json()
		if (settingResponseData.status === 'success') {
			setTimeout(() => {
				setUser(prev =>
					({
						...prev,
						firstName: data.firstName,
						lastName: data.lastName,
						company: data.company,
						phoneNumber: data.phoneNumber,
						jobTitle: data.jobTitle,
						dateOfBirth: data.dateOfBirth,
						gender: data.gender,
						country: data.country
					}))
			}, 2000);
		}
		return settingResponseData;
	}


	return (
		<UserContext.Provider
			value={{
				user,
				isLoggedIn,
				setUser,
				logIn,
				ssoLogin,
				logOut,
				uploadAvatar,
				registerUser,
				updateProfile,
				resetPassword
			}}>
			{children}
		</UserContext.Provider>
	);
};

UserProvider.propTypes = {
	children: PropTypes.any
}

export default UserProvider;
